import request from '@/utils/request';
import qs from 'qs';
const BASEURL = process.env.VUE_APP_API_AUTO;
const SNAPCHAT_BASEURL = process.env.VUE_APP_SNAPCHAT_AUTOGOODS;
// 获取上品列表
export function findByCondition(data) {
  return request({
    url: `/autoUploadAd/findByCondition`,
    method: 'post',
    baseURL: BASEURL,
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 查询投放日志
export function queryByRecordId(params) {
  return request({
    url: '/autoUploadAd/queryByRecordId',
    baseURL: BASEURL,
    method: 'get',
    params,
  });
}

// 上传压缩包
export function uploadZipFiles(data) {
  return request({
    url: '/autoUploadAd/uploadZipFiles',
    baseURL: BASEURL,
    method: 'post',
    timeout: 0,
    data,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    contentType: false,
    processData: false,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 查询草稿详情
export function queryDraftCampaignInfo(params) {
  return request({
    baseURL: BASEURL,
    url: '/autoUploadAd/queryDraftCampaignInfo',
    method: 'get',
    params,
  });
}

// 验证压缩包名称合法性
export function checkZipName(params) {
  return request({
    url: '/autoUploadAd/checkZipName',
    baseURL: BASEURL,
    method: 'get',
    params,
    hideErrorMessage: true,
  });
}
// 取消执行
export function cancelTask(data) {
  return request({
    url: `/autoUploadAd/cancelTask`,
    method: 'post',
    baseURL: BASEURL,
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 查询地区
export function querySnapchatCountry() {
  return request({
    url: '/targeting/querySnapchatCountry',
    baseURL: SNAPCHAT_BASEURL,
    timeout: 90000,
    method: 'get',
  });
}
//兴趣词查询
export function interestQuery(params) {
  const qsString = qs.stringify(params, { indices: false });
  return request({
    url: `/targeting/interestQuery?${qsString}`,
    baseURL: SNAPCHAT_BASEURL,
    timeout: 90000,
    method: 'get',
    // params,
    // paramsSerializer: params => {
    //   return qs.stringify(params, { arrayFormat: 'repeat' })
    // }
  });
}
//查询自定义受众
export function customAudienceQuery(params) {
  return request({
    url: '/targeting/customAudienceQuery',
    baseURL: SNAPCHAT_BASEURL,
    method: 'get',
    params,
    contentType: 'application/json',
  });
}
//保存兴趣词标签
export function interestLabelSave(data) {
  return request({
    url: `/targeting/interestLabelSave`,
    method: 'post',
    baseURL: SNAPCHAT_BASEURL,
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//查询兴趣词标签
export function interestLabelQuery(data) {
  return request({
    url: `/targeting/interestLabelQuery`,
    method: 'post',
    baseURL: SNAPCHAT_BASEURL,
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
//删除兴趣词标签
export function interestLabelDel(data) {
  return request({
    url: `/targeting/interestLabelDel`,
    method: 'post',
    baseURL: SNAPCHAT_BASEURL,
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 查询账户
export function queryAdAccountByPlatform(params) {
  return request({
    url: `/account/queryAdAccountByPlatform`,
    method: 'get',
    timeout: 90000,
    baseURL: SNAPCHAT_BASEURL,
    params,
  });
}
// 账户像素查询
export function querySnapchatPixelByAccountId(params) {
  return request({
    url: '/account/querySnapchatPixelByAccountId',
    method: 'get',
    params,
    baseURL: SNAPCHAT_BASEURL,
  });
}
