<template>
  <div class="searchGroup">
    <el-form
      size="small"
      :model="formData"
      :rules="rules"
      inline
    >
      <el-form-item
        :label="search.label"
        v-for="search in searchs"
        :key="search.prop"
      >
        <el-input
          v-model="formData[search.prop]"
          v-bind="search.formOptions"
          v-if="search.type === 'input'"
        ></el-input>

        <el-select
          v-model="formData[search.prop]"
          v-if="search.type === 'select'"
          v-bind="search.formOptions"
        >
          <el-option
            v-for="option in search.options"
            :label="option[(search.optionConfig && search.optionConfig.label) || 'label']"
            :value="option[(search.optionConfig && search.optionConfig.value) || 'value']"
            :disabled="search.optionConfig && search.optionConfig.disabled && search.optionConfig.disabled(option)"
            :key="option.value"
          ></el-option>
        </el-select>
        <el-date-picker
          v-if="search.type === 'date-picker'"
          v-model="formData[search.prop]"
          v-bind="search.formOptions"
          :value-format="search.formOptions['value-format'] || 'yyyy-MM-dd'"
          style="width: 300px"
          @change="(value) => changeData(value, search)"
        >
        </el-date-picker>
      </el-form-item>
      <slot name="search"></slot>
      <el-form-item>
        <el-button
          type="primary"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          @click="resSet"
          >重置</el-button
        >
        <slot name="opearn"></slot>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/* 
  searchs:   [{}]
    type: 搜索类型    input、select、date-picker
    label: 搜索label
    prop: 搜索的key
    placeholder 默认显示
    formOptions: 对应element配置
    options： []  下拉选时下拉选参数 
      {
        label,
        value,
      }
*/
export default {
  props: {
    searchs: {
      default: [],
      type: Array,
    },
    formInitData: {
      default: () => ({}),
      type: Object,
    },
    rules: Object,
  },
  mounted() {
    this.formData = { ...this.formInitData };
  },
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    // 点击搜索
    search() {
      for (let key in this.formData) {
        this.formData[key] = this.formData[key] !== '' ? this.formData[key] : null;
      }
      this.$emit('search', { ...this.formData });
    },
    // 重置
    resSet() {
      this.formData = { ...this.formInitData };
      this.$nextTick(() => {
        this.$emit('search', { ...this.formData });
      });
    },
    // 时间选择
    changeData(value, searchConfig) {
      let newValue = value || [null, null];
      if (searchConfig.type == 'date-picker') {
        if (searchConfig.startTimeKey) {
          this.$set(this.formData, searchConfig.startTimeKey, newValue[0]);
        }
        if (searchConfig.endTimeKey) {
          this.$set(this.formData, searchConfig.endTimeKey, newValue[1]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  // padding: 16px 16px 0;
  .el-form-item {
    margin-bottom: 16px;
  }
}
</style>
