import request from '@/utils/request';
import { getTpDataSign } from '@/utils/rsa.js';
import qs from 'qs';
const BASE_URL = process.env.VUE_APP_GATEWAY;
const VUE_APP_NEWUPLOAD = process.env.VUE_APP_NEWUPLOAD;
const VUE_APP_API_AUTO = process.env.VUE_APP_API_AUTO;
let mockUrl = 'https://mock.apifox.com/m1/2851801-1449422-default';
console.log(BASE_URL);
const POST = (
  url,
  data,
  config = {
    baseURL: BASE_URL,
  },
  platform,
) => {
  return request({
    method: 'post',
    url: url,
    data,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
      platform: platform,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });
};
const GET = (url, params, config = { baseURL: BASE_URL }) => {
  console.log(config);
  return request({
    method: 'get',
    url: url,
    params,
    ...config,
  });
};
// 获取个号列表
export function myLoginList(data) {
  // return GET('/fastDelivery/asset/myLoginList',data)
  return GET('/gads2/delivery/auto/queryThirdUserLabelByUid', data);
}
// 获取账号列表
export function queryAdAccountByThirdUser(data) {
  return POST(
    `/fastDelivery/asset/queryAdAccountByThirdUser?platform=${data.platform}&thirdUserId=${data.thirdUserId}`,
    data,
  );
}
// 获取像素列表
export function updatePixelByAccount(data) {
  return POST('/fastDelivery/asset/updatePixelByAccount', data);
}
// 获取主页列表
export function updatePageByAccount(data) {
  return POST('/fastDelivery/asset/updatePageByAccount', data);
}
// 获取基础信息查询项
export function getOptionData(data) {
  return GET('/giam/manage/getOptionData', data);
}
// 发布自动上品
export function autoUploadAd(data, signData) {
  return POST('/autoUploadAd/saveAutoUploadAdByFb', data, {
    baseURL: VUE_APP_NEWUPLOAD,
    timeout: 0,
    headers: {
      ...getTpDataSign(signData),
    },
  });
}

// 查询用户最新的缓存
export function queryCatchLeast(data) {
  return GET('/autoUploadAd/queryCatchLeast', data, { baseURL: VUE_APP_API_AUTO });
}
// 查询主页容量
export function pageVolumes(data, config) {
  return GET('/gads2/delivery/ads/pageVolumes', data, { headers: { ...config }, baseURL: BASE_URL });
}
// 查询受益方
export function queryConfigAdBeneficiary(data) {
  return GET('/gads2/delivery/ads/queryConfigAdBeneficiary', data);
}
// 校验链接信息
export function websiteUrlCheck(data, config) {
  console.log(BASE_URL);
  return GET('/gads2/delivery/ads/websiteUrlCheck', data, { headers: { ...config }, baseURL: BASE_URL });
}
// 获取连接信息
export function promoteableSale(data, config) {
  return GET('/gads2/delivery/sale/promotionInfo', data, { headers: { ...config }, baseURL: BASE_URL });
}
